import React from "react";
import {useNavigate} from "react-router-dom";

function Header(){
    
    const navigate = useNavigate();

    async function handleClick(event){
        navigate(event.target.id);
    }

    return(

        <div className="footer"> 

        <h5>© Netscore 2025</h5>

        <div className="footer-info">
            <h5 onClick={handleClick} id="about">About</h5>          
            <h5 onClick={handleClick} id="contact">Contact Us</h5>
        </div>

        </div>
    )
}

export default Header;