import React from 'react';
import Appcss from "./CSS/App.css";
import Bootstrap from "./CSS/Bootstrap.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar.jsx";
import Header from "./components/Header.jsx";
import Fixtures from "./components/Fixtures.jsx";
import Fixture from "./components/Fixture.jsx";
import League from "./components/League.jsx";
import Footer from "./components/Footer.jsx";
import About from "./components/About.jsx";
import Slider from "./components/Slider.jsx";

function App() {
  return (
    <div>
            
    <BrowserRouter>
            
              <Header/>

              <div className="app-wrapper">
                <Sidebar/>
                <Slider/>

                <Routes>
                    <Route index element={<Fixtures/>}/>
                    <Route path="/leagues/:leagueId" element={<League/>}/>
                    <Route path="/leagues/:leagueId/:fixtureDate" element={<League/>}/>
                    <Route path="/fixtures/:fixtureId" element={<Fixture/>}/>
                    <Route path="/about" element={<About/>}/>
                </Routes>
              

              <div className="ads"></div>
              </div>

              <Footer/>
              
            </BrowserRouter>

    </div>
  );
}

export default App;
