import React from 'react';

function About(){
    
    return (
        <div className="about-wrapper"> 
        <p>Welcome to netscore.live, your ultimate destination for real-time sports updates, live scores, and in-depth game analysis. We are passionate about sports and committed to keeping fans like you informed, engaged, and entertained with the latest happenings across the world of athletics.</p>
        <h3>What Sets Us Apart?</h3>
        <ul>
            <li>Live Updates: Instantaneous score updates so you never miss a moment.</li>
            <li>Comprehensive Coverage: From local leagues to global championships, we track it all.</li>
            <li>User-Friendly Design: A sleek and intuitive platform that works seamlessly across devices.</li>
        </ul>
        
        <h3>Our Mission</h3>
        <p>Our mission is simple: to be the most reliable and engaging platform for sports enthusiasts around the world. We aim to connect fans to the action, fostering a vibrant community that celebrates the thrill, passion, and spirit of sports.</p>

        <h3>Join In the Action!</h3>
        <p>Whether you're a die-hard fan, a casual observer, or a stats enthusiast, netscore.live is your go-to resource for all things sports. Stay informed, stay connected, and never miss a game-changing moment.</p>

    </div>
    );

}

export default About